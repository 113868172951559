<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card class="d-flex align-center justify-center flex-column w-100">
        <v-card-title
          class="d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100"
        >
          <h5>Trigger Preview</h5>
          <v-icon class="white--text" @click="dialog = ''"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="d-flex align-start px-6 py-8 pb-6 w-100">

          <div class="d-flex flex-column w-100">
          <v-card-title class="text-subtitle-1 pa-0">
            The following text can be used in an advanced automation rule trigger to target the entities for this Strategy.
          </v-card-title>
          <v-card-text class=" text-subtitle-1 pa-0 d-flex w-100 flex-column">
            <pre><code class="hljs sql"><span v-html="highlighted"></span></code></pre>
          </v-card-text>
          
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end align-center w-100 pb-6  px-6">
          <v-btn
            color="primary"
            @click="copy()"
          >
            Copy
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';

import hljs from "highlight.js/lib/core";
import 'highlight.js/styles/github.css';

import sql from 'highlight.js/lib/languages/sql';

export default Vue.extend({
  name: 'SQLPreview',
  data() {
    return {
      dialog: false,
      highlighted: ''
    };
  },
  props: {
    preview: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  watch: {
    preview(value) {
      if (value && value.code) {
        this.highlighted = hljs.highlight(value.code, { language: 'sql', ignoreIllegals: true }).value;
        this.dialog = true
      }
    }
  },
  methods: {
    async copy() {
      await navigator.clipboard.writeText(this.preview.code);
      this.$notify.success("Copied to clipboard");
      this.dialog = false;
    }
  },
  mounted() {
    hljs.registerLanguage('sql', sql);
  }
});
</script>

<style scoped>

</style>
