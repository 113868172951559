






























// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
export default Vue.extend({
  props: {
    strategyEntityForDeletion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    strategyEntityForDeletion(value) {
      if (value && value.index !== undefined) {
        this.dialog = true;
      }
    },
  },
  methods: {
    async removeEntity() {
      this.$emit('rmEntity', this.strategyEntityForDeletion.index);
      this.dialog = false;
    },
  },
});
