










































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { log } from 'console';
import { DELETE_STRATEGY } from 'src/utils/apollo-queries';
import Vue from 'vue';
export default Vue.extend({
  props: {
    strategyDetailsForDeletion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
    };
  },
  watch: {
    strategyDetailsForDeletion() {
      this.dialog = true;
    },
  },
  methods: {
    async deleteStrategy() {
      this.deleting = true;
      this.$apollo
        .query({
          query: DELETE_STRATEGY,
          variables: {
            strategyId: this.strategyDetailsForDeletion.strategyId,
          },
        })
        .then((res) => {
          console.log('res', res);
          const successMessage = res.data.delStrategy.successMessage;
          console.log('successMessage', successMessage);
          if (successMessage === 'success') {
            console.log('dssd');
            this.$notify.success(this.$t('successMsg.StrategyDeletedMsg'));
            this.$emit('onDeleteStrategy');
          } else {
            console.log('not');
            this.$notify.error(this.$t('successMsg.deleteStrategyFailMsg'));
          }
          this.deleting = false;
          this.dialog = false;
        })
        .catch((error) => {
          this.deleting = false;
        });
    },
  },
});
