var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass:
                "d-flex align-center justify-center flex-column w-100",
            },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100",
                },
                [
                  _c("h5", [_vm._v("Trigger Preview")]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "white--text",
                      on: {
                        click: function ($event) {
                          _vm.dialog = ""
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "d-flex align-start px-6 py-8 pb-6 w-100" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column w-100" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "text-subtitle-1 pa-0" },
                        [
                          _vm._v(
                            " The following text can be used in an advanced automation rule trigger to target the entities for this Strategy. "
                          ),
                        ]
                      ),
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            " text-subtitle-1 pa-0 d-flex w-100 flex-column",
                        },
                        [
                          _c("pre", [
                            _c("code", { staticClass: "hljs sql" }, [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.highlighted),
                                },
                              }),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-card-actions",
                {
                  staticClass:
                    "d-flex justify-end align-center w-100 pb-6  px-6",
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.copy()
                        },
                      },
                    },
                    [_vm._v(" Copy ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }