


































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import moment from 'moment';
import Vue from 'vue';
import { decodeToken } from '@shared/utils/commom.util';
import DecisionAlert from '../components/DecisionAlert.vue';
import DeleteEntity from 'src/components/strategic-view/DeleteEntity.vue';
import SQLPreview from '../components/SQLPre.vue';
import PermissionReadOnlyPopup from './../components/PermissionReadOnlyPopup.vue';
import DeleteStrategy from '../components/strategic-view/DeleteStrategy.vue';
import DeleteTimeline from '../components/strategic-view/DeleteTimeline.vue';
import * as moment from 'moment';
import * as _ from 'lodash';
import {
  GET_ALL_STRATEGIC_VIEW,
  GET_GOALS,
  GET_ALL_TIMELINE_VIEW,
} from 'src/utils/apollo-queries';
import { LSS } from '@core/services';
import { isReadOnly } from '../utils/util';
import {
  UPSERT_STRATEGIC_VIEW,
  UPSERT_TIMELINES_VIEW,
} from 'src/utils/apollo-mutations';

export default Vue.extend({
  name: 'Strategies',
  components: {
    DecisionAlert,
    PermissionReadOnlyPopup,
    DeleteStrategy,
    DeleteTimeline,
    SQLPreview,
    DeleteEntity,
  },
  data() {
    return {
      isCancelOrSaveBtnClicked: false,
      disableDesc: false,
      disableName: false,
      datePickerProps: {
        // min: new Date().toISOString(),
      },
      source: '',
      userName: '',
      categorySelect: false,
      addRowTempate: false,
      syncingStrategy: false,
      syncingTimelines: false,
      syncingTimelinesApiCallInProgress: false,
      editModes: [],
      entityDeletionData: {},
      targetAppsInDialog: [],
      triggerPreview: null,
      decisionAlertFlag: false,
      decisionAlertTitle: 'Are you sure ?',
      decisionAlertMessage: '',
      decisionAlertData: {},
      name: '',
      description: '',
      allStrategies: [],
      selectedGoals: [],
      getGoalsWithApp: [],
      strategyId: null,
      timelineId: null,
      syncChangesFn: () => {
        return;
      },
      permissionReadOnlyMetadata: {},
      dialog: true,
      syncStatus: false,
      locale: 'en',
      loadingApps: false,
      searchedApps: [],
      selectedApp: '',
      strategyDeletionMetadata: {},
      timelineDeletionMetadata: {},
      allStrategyData: [],
      allTimelineData: [],
      searchApp: {
        get() {
          return {
            adamId: 0,
            appName: this.$t('overview.all'),
          };
        },
        set(e) {
          this.searchApp.adamId = e.adamId;
          this.searchApp.appName = e.appName;
        },
      },
      search: '',
      strategyDialog: false,
      saving: false,
      items: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' },
      ],
      timelineHeaders: [
        {
          text: 'Date',
          value: 'createdOn',
        },
        { text: 'Description', value: 'description' },
        { text: 'Category', value: 'category' },
        { text: 'Source', value: 'source' },
        { text: 'Actions', value: 'actions', align: 'center' },
      ],
      timelineItems: [],
      trackTimelineItems: [],
      categoryOptions: [
        'Note',
        'Observation',
        'Milestone',
        'Rule Update',
        'Other',
      ],
      category: 'Note',
      targetEntityHeaders: [
        {
          text: 'App',
          value: 'appName',
        },
        { text: 'App ID', value: 'appId' },
        { text: 'Entity Type', value: 'type' },
        { text: 'Name', value: 'name' },
        { text: 'ID', value: 'id' },
        { text: 'Actions', value: 'actions', align: 'center' },
      ],
      targetEntityItems: [],
      descriptionRules: [
        (v) => !!v || this.$t('strategy.descriptionValid'),
        (v) => (v && v.length <= 250) || this.$t('strategy.textMax'),
      ],
      debouncedSyncTimelineChanges : _.debounce(this.syncTimeLineChnages, 3000),
      debouncedSyncChanges : _.debounce(this.syncChnages, 3000)
    };
  },
  computed: {
    duplicateNameRule() {
      return (value) => {
        const trimmedValue = value.trim(); // Trim leading and trailing whitespaces

        if (!trimmedValue) {
          return 'Name is required';
        }

        if (this.isDuplicateName(trimmedValue)) {
          return 'Duplicate name. Please choose a different name.';
        }

        return true;
      };
    },
    isDisabledViewAdsManagerButton() {
      // Disable the button duplicate name or blank
      return !this.name.trim() || this.isDuplicateName(this.name.trim());
    },
    strategyHeaders: {
      cache: false,
      get() {
        return [
          {
            text: 'Name',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Target Apps',
            value: 'targetEntities',
          },
          {
            text: 'Created On',
            value: 'createdOn',
          },
          {
            text: 'Created By',
            value: 'createdBy',
          },
          {
            text: 'Actions',
            value: 'action',
            sortable: false,
          },
        ];
      },
    },

    appRules: {
      cache: false,
      get(): string {
        return [(v) => !!v || this.$t('createCampaignRules.selectApp')];
      },
    },
  },

  watch: {
    search(text) {
      if (text != '') {
        this.allStrategies = this.allStrategyData.filter(
          (strategy) =>
            strategy.targetEntities.some((el) =>
              el.appName.toLowerCase().includes(text.toLowerCase())
            ) ||
            strategy.name.toLowerCase().includes(text.toLowerCase()) ||
            strategy.createdBy.toLowerCase().includes(text.toLowerCase()) ||
            strategy.createdOn.toLowerCase().includes(text.toLowerCase())
        );
      } else {
        this.allStrategies = this.allStrategyData;
      }
    },
    targetEntityItems(value) {
      if (value) {
        this.targetAppsInDialog = LSS.ownApps.filter((oa) =>
          this.targetEntityItems.map((tei) => tei.appId).includes(oa.adamId)
        );
      }
    },
  },

  apollo: {
    getGoals: {
      query: GET_GOALS,
      update(data) {
        this.getGoalsWithApp = data.getGoals;
      },
    },
    getAllStrategies: {
      query: GET_ALL_STRATEGIC_VIEW,
      update(data) {
        this.allStrategyData = data.getAllStrategies;
        this.listAllStrategies(data.getAllStrategies);
      },
    },
  },

  methods: {
    handleManualSave(){
      this.isCancelOrSaveBtnClicked = true;
      this.callSyncChanges();
    },
    /* Mousedown event is necessary as user can mousdown on cancel or save and mouseup on somewhere else;
    In this case, its not considered a click by JS, so avoiding save in blur event to happen, below logic */
    onMouseDownOfSaveOrCancel(){
      this.isCancelOrSaveBtnClicked = true;
    },
    handleManualCancel(name){
      if(this.strategyId){
        this.cancelEditView(name);
        return;
      }
      if(name = "disableName") this.name = "";
      if(name = "disableDesc") this.description = "";
    },
    handleBlurEvent(){
      /* settimeout is necessary since blur event is handled first than click event of save and cancel btn,
       so as to introduce a delay */
      setTimeout(() => {
        if(this.isCancelOrSaveBtnClicked){
          this.isCancelOrSaveBtnClicked = false;
          return;
        }
        this.callSyncChanges();
      }, 100);
    },
    cancelEditView(name) {
      this[name] = true;
      const strategy = this.allStrategyData.filter(
          (strategy) => strategy.strategyId == this.strategyId
        );
      if(name == "disableName") {
        this.name = strategy[0].name
      } else {
        this.description = strategy[0].description
      }
    },

    enableField(name) {
      this[name] = false;
    },
    redirectToAdsManager(strategyId) {
      this.$router.push({
        path: '/ads-manager',
        query: { strategyId: strategyId },
      });
    },
    isEditMode(index) {
      return this.editModes[index];
    },
    enableEditMode(index) {
      this.editModes = Array(this.timelineItems.length).fill(false);
      this.editModes[index] = true;
    },
    cancelEditMode(index) {
      this.timelineItems = this.timelineItems.filter((tli) => tli.description);
      this.editModes = this.editModes.map((m, i) => {
        if (index == i) m = false;
        return m;
      });
      this.timelineItems[index].description = this.trackTimelineItems[
        index
      ].description;
    },
    async getStrategyTimelines(strategyId) {
      await this.$apollo
        .query({
          query: GET_ALL_TIMELINE_VIEW,
          variables: {
            strategyId: strategyId,
          },
        })
        .then((res) => {
          //   this.timelineItems = res.data.getAllTimelineEntries;
          this.timelineItems = [];
          this.editModes = [];
          this.timelineItems = res.data.getAllTimelineEntries.map((item) => {
            const formattedDate = moment(item.createdOn).format(
              'YYYY-MM-DD HH:mm'
            );
            return { ...item, createdOn: formattedDate };
          });
          this.trackTimelineItems = JSON.parse(
            JSON.stringify(this.timelineItems)
          );
        })
        .catch((error) => {
          this.$notify.error('Unable to get existing timelines');
          console.log('error', error);
        });
    },

    getErrorMessage(item, field) {
      const errors = [];
      if (
        this.categorySelect &&
        field === 'description' &&
        this.descriptionRules
      ) {
        this.descriptionRules.forEach((rule) => {
          const result = rule(item[field]);
          if (result !== true) {
            errors.push(result);
          }
        });
      }
      return errors;
    },
    //delete timeline
    openTimelineDeleteDialog(index, item) {
      if (item.timelineId) {
        this.timelineDeletionMetadata = {
          timelineId: item.timelineId,
          strategyId: item.strategyId,
          now: Date.now(),
        };
      } else {
        this.timelineItems.splice(index, 1);
      }
    },
    //new row on Add Entry

    rowHavingStrategyId() {
      let disable = false;
      let st = this.timelineItems.filter((item) => {
        if (item.timelineId == null) {
          return item;
        }
      });
      if (st.length) {
        disable = true;
      }
      return disable;
    },
    addNewRow() {
      this.addRowTempate = true;
      const now = moment().format('YYYY-MM-DD HH:mm');
      const formattedDate = now;
      const newRow = {
        createdOn: formattedDate,
        category: 'Note',
        timelineId: null,
      };
      this.timelineItems.unshift(newRow);
    },
    localDate(date) {
      if (!date) return;
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },

    removeEntity(index) {
      this.entityDeletionData = { index, now: Date.now() };
    },

    removeEntityExec(index) {
      this.targetEntityItems = this.targetEntityItems.filter(
        (_, i) => i !== index
      );

      this.callSyncChanges();
    },

    changeApp() {
      if (this.searchApp.adamId == 0) {
        this.allStrategies = this.allStrategyData;
      } else {
        this.allStrategies = this.allStrategyData.filter((strategy) =>
          strategy.targetEntities.some(
            (entity) => entity.appId === this.searchApp.adamId
          )
        );
      }
    },
    onUpdate() {
      this.$apollo.queries.getAllStrategies.refetch();
    },
    onTimelineUpdate(strategyId) {
      if (strategyId != '') {
        this.getStrategyTimelines(strategyId);
      }
    },
    populateApps() {
      this.searchedApps = LSS.ownApps; // takes no extra memory since they bot point to same object(array)
      // this.searchedApps = [...LSS.ownApps] //takes less memory
      // this.searchedApps = _.cloneDeep(LSS.ownApps) //takes more memory

      const attrExistAll = this.searchedApps.filter((e) => e.attrExist);
      this.searchedApps.unshift({
        adamId: 0,
        appName: this.searchedApps.length > 0 ? 'All' : 'No Apps',
        appIcon: 'mdi-cellphone',
        attrExist:
          this.searchedApps.length == attrExistAll.length ? true : false,
      });
      this.searchApp = {
        adamId: 0,
        appName: this.searchedApps.length > 0 ? 'All' : 'No Apps',
        attrExist:
          this.searchedApps.length == attrExistAll.length ? true : false,
        appIcon: '',
      };
      this.loadingApps = false;
    },
    previewTrigger() {
      let triggerCodeArray = [];
      const uniqTargetTypes = _.uniq(
        this.targetEntityItems.map((te) => te.type)
      );

      this.targetEntityItems.forEach((te) => {
        triggerCodeArray.push(`${te.type.toLowerCase()}.id = ${te.id}`);
      });

      let triggerCode = '( \n';
      triggerCode += triggerCodeArray.join(' or \n');
      triggerCode += '\n) \n';

      triggerCodeArray = [];

      uniqTargetTypes.forEach((type) => {
        triggerCodeArray.push(
          `${type.toLowerCase()}.state = ${
            type === 'KEYWORD' ? '"ACTIVE"' : '"ENABLED"'
          }`
        );
      });

      triggerCode += 'and \n( \n';

      triggerCode += triggerCodeArray.join('and \n');

      triggerCode += '\n)';

      this.triggerPreview = { code: triggerCode, now: Date.now() };
    },
    addTargetMsg() {
      this.decisionAlertTitle =
        "From within Ads Manager, select campaigns, adgroups and keywords to target for this Strategy and then click the 'Add to Strategy' button.";
      this.decisionAlertFlag = true;
    },
    clearDecisionModel() {
      this.decisionAlertFlag = false;
      this.decisionAlertTitle = '';
    },
    openStrategyInDialog(item) {
      this.disableDesc = true;
      this.disableName = true;
      this.name = item.name;
      this.description = item.description;

      let c = item.targetEntities.filter(
        (strategy) => strategy.type == 'CAMPAIGN'
      );
      let a = item.targetEntities.filter(
        (strategy) => strategy.type == 'ADGROUP'
      );
      let k = item.targetEntities.filter(
        (strategy) => strategy.type == 'KEYWORD'
      );

      this.targetEntityItems = c.concat(a, k);
      this.selectedGoals = item.goals || [];

      this.strategyId = item.strategyId;
      if (this.strategyId != '') {
        this.getStrategyTimelines(this.strategyId);
      }
      this.strategyDialog = true;
    },
    openStrategyDeleteDialog(item) {
      this.strategyDeletionMetadata = {
        strategyId: item.strategyId,
        now: Date.now(),
      };
    },
    listAllStrategies(allStrategies) {
      // Extracting targetEntities array
      const strategies = allStrategies.slice().sort((a, b) => {
        if (!a.targetEntities || a.targetEntities.length === 0) return 1;
        if (!b.targetEntities || b.targetEntities.length === 0) return -1;

        const appNameA = a.targetEntities[0].appName.toLowerCase();
        const appNameB = b.targetEntities[0].appName.toLowerCase();

        if (appNameA < appNameB) return -1;
        if (appNameA > appNameB) return 1;
        return 0;
      });

      strategies.forEach((strategy) => {
        const targetEntities = strategy.targetEntities;
        let appIcon = '';
        const firstAppName =
          targetEntities && targetEntities.length > 0
            ? targetEntities[0].appName
            : null;
        //get appIcon

        const restOfAppsCount =
          targetEntities && targetEntities.length > 0
            ? _.uniq(targetEntities.map((te) => te.appId)).length - 1
            : 0;
        if (LSS.ownApps && LSS.ownApps.length > 0) {
          for (let index = 0; index < LSS.ownApps.length; index++) {
            const element = LSS.ownApps[index];
            if (targetEntities && targetEntities.length > 0) {
              if (
                targetEntities[0] &&
                element.adamId == targetEntities[0].appId
              ) {
                appIcon = element.appIcon;
              }
            }
          }
        }
        const combinedEntities = [
          {
            appName: firstAppName,
            remainingApps: restOfAppsCount,
            appIcon: appIcon,
          },
        ];
        strategy.combinedEntities = combinedEntities;
      });

      this.allStrategies = allStrategies;
    },
    callTimelineChanges(item) {
      if (!item.description && item.category) {
        this.categorySelect = true;
        return;
      }

      this.syncingTimelines = true;
      this.categorySelect = false;
      this.debouncedSyncTimelineChanges(item);
    },
    callSyncChanges() {
      if (this.syncingStrategy) {
        return this.$notify.error('Saving already in progress');
      }
      if (!this.name.trim() || this.isDuplicateName(this.name.trim())) {
        return false;
      }
      this.duplicateNameError = '';
      this.syncingStrategy = true;
      this.debouncedSyncChanges();
    },

    async syncChnages() {
      this.duplicateNameError = '';
      /* Commenting below coz its causing to save & cancel btn to appear briefly 
      as non-disabled before */
      // this.syncingStrategy = false;
      if (this.strategyId !== null && this.strategyDialog && this.name) {
        if (this.isDuplicateName(this.name, this.strategyId)) {
          this.duplicateNameError = 'Error: Duplicate strategy name';
          return;
        }
        await this.upsertStrategy();
      } else if (this.strategyId == null && this.strategyDialog && this.name) {
        if (this.isDuplicateName(this.name, this.strategyId)) {
          this.duplicateNameError =
            'Error: Duplicate name. Please choose a different name.';
          return;
        }
        await this.upsertStrategy();
      }
      this.syncingStrategy = false;
      this.$apollo.queries.getAllStrategies.refetch();
    },

    isDuplicateName(name, strategyId) {
      // return this.allStrategies.some(strategy => {
      //   if(strategy.name === name && (strategyId == null || strategy.strategyId !== strategyId)) {
      //     return true;
      //   }
      // });
      const otherStrategies = this.allStrategies.filter(
        (strategy) => strategy.strategyId != this.strategyId
      );
      return otherStrategies.some((strategy) => strategy.name === name);
    },

    async syncTimeLineChnages(item) {
      this.syncingTimelinesApiCallInProgress = true;
      this.$refs.categorySelect.blur(); // closing the category select dropdown if its opened
      await this.upsertTimelines(item);

      this.syncingTimelines = false;
      this.syncingTimelinesApiCallInProgress = false;
      // this.$apollo.queries.getAllStrategies.refetch();
    },
    async upsertStrategy() {
      const data = {
        name: this.name,
        description: this.description,
        goals: this.selectedGoals,
        targetEntities: this.targetEntityItems,
      };
      if (this.strategyId) data.strategyId = this.strategyId;

      const result = await this.$apollo.mutate({
        mutation: UPSERT_STRATEGIC_VIEW,
        variables: {
          strategy: data,
        },
      });

      if (result.data.upsertStrategy.successMessage) {
        this.strategyId = result.data.upsertStrategy.data;
      } else {
        this.$notify.error('An error occurred while saving strategy');
      }
      //view Timelines
      if (this.strategyId) {
        this.disableDesc = true;
        this.disableName = true;
        this.getStrategyTimelines(this.strategyId);
      }
    },
    async upsertTimelines(item) {
        const data = {
          createdOn: item.createdOn ? new Date(item.createdOn) : null,
          description: item.description,
          category: item.category ? item.category : this.category,
        };
        if (this.strategyId) data.strategyId = this.strategyId;
        if (item.timelineId) data.timelineId = item.timelineId;

        const result = await this.$apollo.mutate({
          mutation: UPSERT_TIMELINES_VIEW,
          variables: {
            strategyTimeline: data,
          },
        });

        if (result.data.upsertTimeline.successMessage) {
          this.timelineId = result.data.upsertTimeline.data;
        } else {
          this.$notify.error('An error occurred while saving timeline');
        }
        await this.getStrategyTimelines(this.strategyId);
    },
    closeDialog() {
      if (this.syncingTimelines) {
        return this.$notify.error(
          'Saving timeline. Please wait before closing the dialog'
        );
      }
      this.disableDesc = false;
      this.disableName = false;
      this.name = '';
      this.description = '';
      this.targetEntityItems = [];
      this.selectedGoals = [];
      this.strategyId = null;
      this.strategyDialog = false;
      this.timelineItems = [];
      this.editModes = [];
      this.timelineId = null;
      this.syncingTimelines = false;
      this.syncingStrategy = false;
      this.flushAllDebouncedCalls();
      this.$refs.strategyName.resetValidation();
      this.$apollo.queries.getAllStrategies.refetch();
    },
    flushAllDebouncedCalls(){
      this.debouncedSyncChanges.flush();
      this.debouncedSyncTimelineChanges.flush();
    },
    isReadOnly() {
      return isReadOnly();
    },
    openPermissionReadOnlyPopup() {
      this.permissionReadOnlyMetadata = {
        date: moment.now(),
      };
    },
    openCreateDialog() {
      this.strategyDialog = true;
      this.strategyId = null;
    },
  },
  mounted() {
    this.$apollo.queries.getAllStrategies.refetch();
    this.syncStatus = LSS.activeSync;
    Vue.prototype.$sckt.on('syncprogress:event', () => {
      this.syncStatus = LSS.activeSync;
    });
    this.locale = localStorage.getItem('locale') || 'en';

    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode;
    });
    this.populateApps();
  },
  async created() {
    if (!this.$route.query.tour) {
      if (this.$route.query.extractedValues) {
        this.targetEntityItems = this.$route.query.extractedValues;
        this.strategyDialog = true;
      }
      await this.$apollo.queries.getAllStrategies.refetch();
      const strategyId = this.$route.query.strategyId;
      const strategyToOpen = this.allStrategyData.find(
        (strategy) => strategy.strategyId === strategyId
      );
      if (decodeToken(LSS.token)) {
        const fname = decodeToken(LSS.token).FirstName || 'S';
        const lname = decodeToken(LSS.token).LastName || 'N';
        this.userName = fname + ' ' + lname;
      }
     
      if (strategyId) {
        this.$router.replace('/strategies');
        this.openStrategyInDialog(strategyToOpen);
      }
    }
  },
});
