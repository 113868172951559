










































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { log } from 'console';
import { DELETE_TIMELINE } from 'src/utils/apollo-queries';
import Vue from 'vue';
export default Vue.extend({
  props: {
    timelineDetailsForDeletion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
    };
  },
  watch: {
    timelineDetailsForDeletion() {
      this.dialog = true;
    },
  },
  methods: {
    async deleteTimeline() {
      this.deleting = true;
      this.$apollo
        .query({
          query: DELETE_TIMELINE,
          variables: {
            timelineId: this.timelineDetailsForDeletion.timelineId,
          },
        })
        .then((res) => {
          const successMessage = res.data.delStrategyTimeline.successMessage;
          if (successMessage === 'success') {
            this.$notify.success(this.$t('successMsg.TimelineDeletedMsg'));
            this.$emit(
              'onDeleteTimeline',
              this.timelineDetailsForDeletion.strategyId
            );
          } else {
                 this.$notify.error(this.$t('successMsg.deleteTimelineFailMsg'));
          }
          this.deleting = false;
          this.dialog = false;
        })
        .catch((error) => {
          this.deleting = false;
        });
    },
  },
});
